.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-grow infinite 1s linear;
  animation-direction: alternate;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282c34;
}

.App-header-countdown {
  background-color: #00FF00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #282c34;
}

@font-face {
  font-family: 'MarcellusSC-Regular';
  src: url('./font/MarcellusSC-Regular.ttf') format('truetype');
}

.font-countdown-timer {
  font-family: "MarcellusSC-Regular";
  color: #ffffff;
  font-size: 62px;
  text-shadow: 0px 0px 5px black;
  font-weight: bold;
}

.Paladino {
  background-color: red;
  color: white;
  border-radius: 100px;
}

.CavaliereScelto {
  background-color: orangered;
  color: white;
  border-radius: 100px;
}

.Cavaliere {
  background-color: #cc7900;
  color: white;
  border-radius: 100px;
}

.Campione {
  background-color: #f8c300;
  color: white;
  border-radius: 100px;
}

.BalestriereScelto {
  background-color: #00d166;
  color: white;
  border-radius: 100px;
}

.Balestriere {
  background-color: #00c094;
  color: white;
  border-radius: 100px;
}

.Arciere {
  background-color: rgb(0, 105, 152);
  color: white;
  border-radius: 100px;
}

.Milizia {
  background-color: #0099e1;
  color: white;
  border-radius: 100px;
}

.PecoraEsploratrice {
  background-color: #00e1e1;
  color: white;
  border-radius: 100px;
}

.PlayerBox {
  height: 25px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  border: 2px solid black;
  color: black !important;
}

.Player1 {
  background-color: #405BFE;
}

.Player2 {
  background-color: #FF0000;
}

.Player3 {
  background-color: #00FF00;
}

.Player4 {
  background-color: #FFFF00;
}

.Player5 {
  background-color: #00FFFF;
}

.Player6 {
  background-color: #FF57B3;
}

.Player7 {
  background-color: #797979;
}

.Player8 {
  background-color: #FF9600;
}

.h1 {
  font-size: calc(24px + 1vmin);
}

.h2 {
  font-size: calc(22px + 1vmin);
}

.h3 {
  font-size: calc(20px + 1vmin);
}

.h4 {
  font-size: calc(18px + 1vmin);
}

.h5 {
  font-size: calc(16px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black;
}

.nav-link {
  color: grey;
}

.nav-tabs .nav-link:hover {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-grow {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(0.93);
  }
}